import React from "react";
import IndexPage from "./index"

const IndexPageNL = () => (
  <IndexPage i18n="nl"/>
);

export default IndexPageNL;


